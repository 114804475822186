import { useState, useEffect, ReactNode } from 'react';
import NotFound from 'components/layout/App/NotFound';
import Page from 'components/layout/Page';

type FetchComponent = () => Promise<{ default: ReactNode }>;

/**
 * Will wrap a <Route />'s component when it needs to be fetched asynchronously
 */
const wrapAsync =
  (fetchComponent: FetchComponent, loader = <Page title="-" isLoading />) =>
  (props: object) => {
    const [Component, setComponent] = useState(null);

    const init = () => {
      fetchComponent()
        .then(({ default: LoadedComponent }) => {
          setComponent(() => LoadedComponent);
        })
        .catch(() => {
          setComponent(() => NotFound);
        });
    };

    useEffect(init, []);

    if (!Component) return loader;

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  };

export default wrapAsync;
